exports = module.exports = require("../../node_modules/_css-loader@0.28.11@css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\nbutton.button_3wbJuAzq {\r\n  display: inline-block;\r\n  width: auto;\r\n  font-size: 1.1em;\r\n  color: #2196f3;\r\n  background: transparent;\n}\r\n", "", {"version":3,"sources":["D:/blog/client/src/common/ButtonAntiColor.vue"],"names":[],"mappings":";AACA;EACE,sBAAsB;EACtB,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,wBAAwB;CACzB","file":"ButtonAntiColor.vue","sourcesContent":["\nbutton.button {\r\n  display: inline-block;\r\n  width: auto;\r\n  font-size: 1.1em;\r\n  color: #2196f3;\r\n  background: transparent;\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"button": "button_3wbJuAzq"
};