exports = module.exports = require("../../node_modules/_css-loader@0.28.11@css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n.textarea_39ZGhYdy {\r\n  display: block;\r\n  -webkit-box-flex: 1;\r\n      -ms-flex: 1;\r\n          flex: 1;\r\n  -webkit-box-sizing: border-box;\r\n          box-sizing: border-box;\r\n  width: 100%;\r\n  max-width: 1200px;\r\n  min-height: 140px;\r\n  padding: 4px 10px;\r\n  margin: 0;\r\n  border: 1px solid #d9d9d9;\r\n  border-radius: 4px;\r\n  resize: none;\r\n  line-height: 1.5;\r\n  color: rgba(0, 0, 0, .8);\n}\r\n", "", {"version":3,"sources":["D:/blog/client/src/common/BaseTextArea.vue"],"names":[],"mappings":";AACA;EACE,eAAe;EACf,oBAAoB;MAChB,YAAY;UACR,QAAQ;EAChB,+BAA+B;UACvB,uBAAuB;EAC/B,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,UAAU;EACV,0BAA0B;EAC1B,mBAAmB;EACnB,aAAa;EACb,iBAAiB;EACjB,yBAAyB;CAC1B","file":"BaseTextArea.vue","sourcesContent":["\n.textarea {\r\n  display: block;\r\n  -webkit-box-flex: 1;\r\n      -ms-flex: 1;\r\n          flex: 1;\r\n  -webkit-box-sizing: border-box;\r\n          box-sizing: border-box;\r\n  width: 100%;\r\n  max-width: 1200px;\r\n  min-height: 140px;\r\n  padding: 4px 10px;\r\n  margin: 0;\r\n  border: 1px solid #d9d9d9;\r\n  border-radius: 4px;\r\n  resize: none;\r\n  line-height: 1.5;\r\n  color: rgba(0, 0, 0, .8);\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"textarea": "textarea_39ZGhYdy"
};