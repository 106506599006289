exports = module.exports = require("../../node_modules/_css-loader@0.28.11@css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n.article_JiCb5zJ- {\r\n  display: -webkit-box;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -webkit-box-orient: vertical;\r\n  -webkit-box-direction: normal;\r\n      -ms-flex-flow: column;\r\n          flex-flow: column;\r\n  -webkit-box-sizing: border-box;\r\n          box-sizing: border-box;\r\n  padding: 14px;\r\n  margin-top: 10px;\r\n  border-radius: 6px;\r\n  background: #fff;\r\n  -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12);\r\n          box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12);\n}\r\n", "", {"version":3,"sources":["D:/blog/client/src/common/BaseCard.vue"],"names":[],"mappings":";AACA;EACE,qBAAqB;EACrB,qBAAqB;EACrB,cAAc;EACd,6BAA6B;EAC7B,8BAA8B;MAC1B,sBAAsB;UAClB,kBAAkB;EAC1B,+BAA+B;UACvB,uBAAuB;EAC/B,cAAc;EACd,iBAAiB;EACjB,mBAAmB;EACnB,iBAAiB;EACjB,qHAAqH;UAC7G,6GAA6G;CACtH","file":"BaseCard.vue","sourcesContent":["\n.article {\r\n  display: -webkit-box;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -webkit-box-orient: vertical;\r\n  -webkit-box-direction: normal;\r\n      -ms-flex-flow: column;\r\n          flex-flow: column;\r\n  -webkit-box-sizing: border-box;\r\n          box-sizing: border-box;\r\n  padding: 14px;\r\n  margin-top: 10px;\r\n  border-radius: 6px;\r\n  background: #fff;\r\n  -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12);\r\n          box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12);\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"article": "article_JiCb5zJ-"
};