exports = module.exports = require("../../node_modules/_css-loader@0.28.11@css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n.button_2gLHUx3y {\r\n  display: block;\r\n  -webkit-box-sizing: border-box;\r\n          box-sizing: border-box;\r\n  width: 100%;\r\n  padding: 0 10px;\r\n  border-radius: 4px;\r\n  font-size: 1.3em;\r\n  line-height: 42px;\r\n  text-align: center;\r\n  color: #fff;\r\n  background: #2196f3;\r\n  cursor: pointer\n}\n.button_2gLHUx3y:focus {\r\n  outline: none;\n}\r\n", "", {"version":3,"sources":["D:/blog/client/src/common/BaseButton.vue"],"names":[],"mappings":";AACA;EACE,eAAe;EACf,+BAA+B;UACvB,uBAAuB;EAC/B,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EACZ,oBAAoB;EACpB,eAAe;CAChB;AACD;EACE,cAAc;CACf","file":"BaseButton.vue","sourcesContent":["\n.button {\r\n  display: block;\r\n  -webkit-box-sizing: border-box;\r\n          box-sizing: border-box;\r\n  width: 100%;\r\n  padding: 0 10px;\r\n  border-radius: 4px;\r\n  font-size: 1.3em;\r\n  line-height: 42px;\r\n  text-align: center;\r\n  color: #fff;\r\n  background: #2196f3;\r\n  cursor: pointer\n}\n.button:focus {\r\n  outline: none;\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"button": "button_2gLHUx3y"
};