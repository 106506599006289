exports = module.exports = require("../../node_modules/_css-loader@0.28.11@css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n.box_3A-AwUaR {\r\n  display: -webkit-box;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -webkit-box-pack: center;\r\n      -ms-flex-pack: center;\r\n          justify-content: center;\r\n  -webkit-box-align: center;\r\n      -ms-flex-align: center;\r\n          align-items: center\n}\n.box_3A-AwUaR button {\r\n  width: auto;\n}\r\n", "", {"version":3,"sources":["D:/blog/client/src/common/ButtonBox.vue"],"names":[],"mappings":";AACA;EACE,qBAAqB;EACrB,qBAAqB;EACrB,cAAc;EACd,yBAAyB;MACrB,sBAAsB;UAClB,wBAAwB;EAChC,0BAA0B;MACtB,uBAAuB;UACnB,mBAAmB;CAC5B;AACD;EACE,YAAY;CACb","file":"ButtonBox.vue","sourcesContent":["\n.box {\r\n  display: -webkit-box;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -webkit-box-pack: center;\r\n      -ms-flex-pack: center;\r\n          justify-content: center;\r\n  -webkit-box-align: center;\r\n      -ms-flex-align: center;\r\n          align-items: center\n}\n.box button {\r\n  width: auto;\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"box": "box_3A-AwUaR"
};