exports = module.exports = require("../../../node_modules/_css-loader@0.28.11@css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n.title_2RnvwWiY {\r\n  padding: 10px;\r\n  font-weight: bold;\r\n  font-size: 1.2em;\r\n  line-height: 2;\n}\r\n", "", {"version":3,"sources":["D:/blog/client/src/components/Alert/AlertWithButtonBox.vue"],"names":[],"mappings":";AACA;EACE,cAAc;EACd,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;CAChB","file":"AlertWithButtonBox.vue","sourcesContent":["\n.title {\r\n  padding: 10px;\r\n  font-weight: bold;\r\n  font-size: 1.2em;\r\n  line-height: 2;\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"title": "title_2RnvwWiY"
};