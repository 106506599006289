exports = module.exports = require("../../node_modules/_css-loader@0.28.11@css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n.backBox_2D_1F2GX {\r\n  display: -webkit-box;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -webkit-box-align: center;\r\n      -ms-flex-align: center;\r\n          align-items: center;\r\n  font-size: 1.1em;\r\n  cursor: pointer;\n}\n.svg_2y5bfIp0 {\r\n  margin-right: 6px;\n}\r\n", "", {"version":3,"sources":["D:/blog/client/src/common/BaseBack.vue"],"names":[],"mappings":";AACA;EACE,qBAAqB;EACrB,qBAAqB;EACrB,cAAc;EACd,0BAA0B;MACtB,uBAAuB;UACnB,oBAAoB;EAC5B,iBAAiB;EACjB,gBAAgB;CACjB;AACD;EACE,kBAAkB;CACnB","file":"BaseBack.vue","sourcesContent":["\n.backBox {\r\n  display: -webkit-box;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -webkit-box-align: center;\r\n      -ms-flex-align: center;\r\n          align-items: center;\r\n  font-size: 1.1em;\r\n  cursor: pointer;\n}\n.svg {\r\n  margin-right: 6px;\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"backBox": "backBox_2D_1F2GX",
	"svg": "svg_2y5bfIp0"
};