exports = module.exports = require("../../node_modules/_css-loader@0.28.11@css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n.title_2cRpvvWT {\n  -ms-flex-item-align: center;\n      align-self: center;\n  padding: 6px 0;\n  font-weight: normal;\n  font-size: 1.6em;\n  line-height: 2;\n  text-align: center;\n  cursor: default;\n}\n", "", {"version":3,"sources":["D:/blog/client/src/common/BaseTitle.vue"],"names":[],"mappings":";AACA;EACE,4BAA4B;MACxB,mBAAmB;EACvB,eAAe;EACf,oBAAoB;EACpB,iBAAiB;EACjB,eAAe;EACf,mBAAmB;EACnB,gBAAgB;CACjB","file":"BaseTitle.vue","sourcesContent":["\n.title {\n  -ms-flex-item-align: center;\n      align-self: center;\n  padding: 6px 0;\n  font-weight: normal;\n  font-size: 1.6em;\n  line-height: 2;\n  text-align: center;\n  cursor: default;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"title": "title_2cRpvvWT"
};