exports = module.exports = require("../../../node_modules/_css-loader@0.28.11@css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n.wrap_3IMcVB0- {\n  position: relative;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  max-width: 1200px;\n  height: 220px;\n  padding: 10px;\n  margin: 0 auto;\n  border-radius: 4px;\n  background: #fff;\n}\n.buttonBox_qxPN6HkX {\n  margin-top: 10px;\n}\n", "", {"version":3,"sources":["D:/blog/client/src/components/Comment/CommentForm.vue"],"names":[],"mappings":";AACA;EACE,mBAAmB;EACnB,+BAA+B;UACvB,uBAAuB;EAC/B,kBAAkB;EAClB,cAAc;EACd,cAAc;EACd,eAAe;EACf,mBAAmB;EACnB,iBAAiB;CAClB;AACD;EACE,iBAAiB;CAClB","file":"CommentForm.vue","sourcesContent":["\n.wrap {\n  position: relative;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  max-width: 1200px;\n  height: 220px;\n  padding: 10px;\n  margin: 0 auto;\n  border-radius: 4px;\n  background: #fff;\n}\n.buttonBox {\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrap": "wrap_3IMcVB0-",
	"buttonBox": "buttonBox_qxPN6HkX"
};