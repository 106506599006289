exports = module.exports = require("../../node_modules/_css-loader@0.28.11@css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n.backBox_34mDsTjb {\r\n  display: -webkit-box;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -webkit-box-align: center;\r\n      -ms-flex-align: center;\r\n          align-items: center;\r\n  font-size: 1.1em;\n}\n.svg_2_iBnWpt {\r\n  margin-right: 6px;\n}\r\n", "", {"version":3,"sources":["D:/blog/client/src/common/BaseAdd.vue"],"names":[],"mappings":";AACA;EACE,qBAAqB;EACrB,qBAAqB;EACrB,cAAc;EACd,0BAA0B;MACtB,uBAAuB;UACnB,oBAAoB;EAC5B,iBAAiB;CAClB;AACD;EACE,kBAAkB;CACnB","file":"BaseAdd.vue","sourcesContent":["\n.backBox {\r\n  display: -webkit-box;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -webkit-box-align: center;\r\n      -ms-flex-align: center;\r\n          align-items: center;\r\n  font-size: 1.1em;\n}\n.svg {\r\n  margin-right: 6px;\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"backBox": "backBox_34mDsTjb",
	"svg": "svg_2_iBnWpt"
};