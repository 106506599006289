<template>
  <CommentForm operate="update" />
</template>
<script>
import CommentForm from './CommentForm'

export default {
  components: {
    CommentForm
  }
}
</script>
